var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"65%"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"grey lighten-2"}},[_c('v-toolbar-title',[_vm._v(" Contato "),_c('span',{staticClass:"body-2"},[_c('i',[_vm._v(_vm._s(_vm.contact ? ("Editando #" + (_vm.contact.id)) : 'Novo'))])])]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"primary","absolute":"","bottom":"","indeterminate":""}}):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"pt-4 text-center"},[_c('validation-observer',{ref:"observer"},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"typeOfContactId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.typesOfContacts,"item-value":"id","item-text":"description","label":"Tipo","hide-details":"auto","error-messages":errors},model:{value:(_vm.fields.typeOfContactId),callback:function ($$v) {_vm.$set(_vm.fields, "typeOfContactId", $$v)},expression:"fields.typeOfContactId"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Nome","error-messages":errors},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Usuário","error-messages":errors},model:{value:(_vm.fields.username),callback:function ($$v) {_vm.$set(_vm.fields, "username", $$v)},expression:"fields.username"}})]}}])})],1),(!this.contact)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Senha","error-messages":errors},model:{value:(_vm.fields.password),callback:function ($$v) {_vm.$set(_vm.fields, "password", $$v)},expression:"fields.password"}})]}}],null,false,350690606)})],1):_vm._e(),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"E-mail","error-messages":errors},model:{value:(_vm.fields.email),callback:function ($$v) {_vm.$set(_vm.fields, "email", $$v)},expression:"fields.email"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"email2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"E-mail 2","error-messages":errors},model:{value:(_vm.fields.email2),callback:function ($$v) {_vm.$set(_vm.fields, "email2", $$v)},expression:"fields.email2"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Telefone","error-messages":errors},model:{value:(_vm.fields.telephone),callback:function ($$v) {_vm.$set(_vm.fields, "telephone", $$v)},expression:"fields.telephone"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"telephone2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Telefone 2","error-messages":errors},model:{value:(_vm.fields.telephone2),callback:function ($$v) {_vm.$set(_vm.fields, "telephone2", $$v)},expression:"fields.telephone2"}})]}}])})],1),(!_vm.isTownHall)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"politicalParty"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Partido político","error-messages":errors},model:{value:(_vm.fields.politicalParty),callback:function ($$v) {_vm.$set(_vm.fields, "politicalParty", $$v)},expression:"fields.politicalParty"}})]}}],null,false,3787401565)})],1):_vm._e(),(!_vm.isTownHall)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"placeOfBirth"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Naturalidade","error-messages":errors},model:{value:(_vm.fields.placeOfBirth),callback:function ($$v) {_vm.$set(_vm.fields, "placeOfBirth", $$v)},expression:"fields.placeOfBirth"}})]}}],null,false,598796779)})],1):_vm._e(),(!_vm.isTownHall)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"rg"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#################'),expression:"'#################'"}],attrs:{"hide-details":"auto","label":"RG","error-messages":errors},model:{value:(_vm.fields.rg),callback:function ($$v) {_vm.$set(_vm.fields, "rg", $$v)},expression:"fields.rg"}})]}}],null,false,4229372825)})],1):_vm._e(),(!_vm.isTownHall)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"hide-details":"auto","label":"CPF","error-messages":errors},model:{value:(_vm.fields.cpf),callback:function ($$v) {_vm.$set(_vm.fields, "cpf", $$v)},expression:"fields.cpf"}})]}}],null,false,2416352825)})],1):_vm._e(),(!_vm.isTownHall)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"job"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Profissão","error-messages":errors},model:{value:(_vm.fields.job),callback:function ($$v) {_vm.$set(_vm.fields, "job", $$v)},expression:"fields.job"}})]}}],null,false,452740233)})],1):_vm._e(),(_vm.isTownHall)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"site"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Site","error-messages":errors},model:{value:(_vm.fields.site),callback:function ($$v) {_vm.$set(_vm.fields, "site", $$v)},expression:"fields.site"}})]}}],null,false,1380458976)})],1):_vm._e(),(!_vm.isTownHall)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"genreId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.genres,"item-value":"id","item-text":"description","label":"Gênero","hide-details":"auto","error-messages":errors},model:{value:(_vm.fields.genreId),callback:function ($$v) {_vm.$set(_vm.fields, "genreId", $$v)},expression:"fields.genreId"}})]}}],null,false,250019506)})],1):_vm._e(),(_vm.isTownHall)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"associationId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.associations,"item-value":"id","item-text":"abbreviation","label":"Associação","hide-details":"auto","error-messages":errors},model:{value:(_vm.fields.associationId),callback:function ($$v) {_vm.$set(_vm.fields, "associationId", $$v)},expression:"fields.associationId"}})]}}],null,false,1365381205)})],1):_vm._e(),(!_vm.isTownHall)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"educationDegreeId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.educationDegrees,"item-value":"id","item-text":"description","label":"Escolaridade","hide-details":"auto","error-messages":errors},model:{value:(_vm.fields.educationDegreeId),callback:function ($$v) {_vm.$set(_vm.fields, "educationDegreeId", $$v)},expression:"fields.educationDegreeId"}})]}}],null,false,2473424353)})],1):_vm._e(),(!_vm.isTownHall)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"civilStateId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.civilStates,"item-value":"id","item-text":"description","label":"Estado Civil","hide-details":"auto","error-messages":errors},model:{value:(_vm.fields.civilStateId),callback:function ($$v) {_vm.$set(_vm.fields, "civilStateId", $$v)},expression:"fields.civilStateId"}})]}}],null,false,1532681976)})],1):_vm._e(),(_vm.isTownHall)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Endereço","error-messages":errors},model:{value:(_vm.fields.address),callback:function ($$v) {_vm.$set(_vm.fields, "address", $$v)},expression:"fields.address"}})]}}],null,false,3118587075)})],1):_vm._e(),(_vm.isTownHall)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"cep"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],attrs:{"hide-details":"auto","label":"CEP","error-messages":errors},model:{value:(_vm.fields.cep),callback:function ($$v) {_vm.$set(_vm.fields, "cep", $$v)},expression:"fields.cep"}})]}}],null,false,3887880249)})],1):_vm._e(),(!_vm.isTownHall)?_c('v-col',{attrs:{"md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('validation-provider',{attrs:{"vid":"birthDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Data de Nascimento","hide-details":"auto","error-messages":errors,"append-icon":"mdi-calendar"},on:{"blur":function($event){_vm.fields.birthDate = _vm.dateFormatIso(_vm.formatedBirthDate)},"click:append":function($event){_vm.birthDateMenu = true}},model:{value:(_vm.formatedBirthDate),callback:function ($$v) {_vm.formatedBirthDate=$$v},expression:"formatedBirthDate"}})]}}],null,true)})]}}],null,false,3809791542),model:{value:(_vm.birthDateMenu),callback:function ($$v) {_vm.birthDateMenu=$$v},expression:"birthDateMenu"}},[_c('v-date-picker',{staticClass:"ma-0",attrs:{"no-title":""},on:{"input":function($event){_vm.birthDateMenu = false}},model:{value:(_vm.fields.birthDate),callback:function ($$v) {_vm.$set(_vm.fields, "birthDate", $$v)},expression:"fields.birthDate"}})],1)],1):_vm._e(),(_vm.isMayor || _vm.isDeputyMayor)?_c('v-col',{attrs:{"md":"6"}},[_c('v-switch',{staticClass:"mt-0 pt-4",attrs:{"label":"Reeleito","hide-details":""},model:{value:(_vm.fields.reelected),callback:function ($$v) {_vm.$set(_vm.fields, "reelected", $$v)},expression:"fields.reelected"}})],1):_vm._e(),_c('v-col',{attrs:{"md":"6"}},[_c('v-switch',{staticClass:"mt-0 pt-4",attrs:{"label":"Ativo","hide-details":""},model:{value:(_vm.fields.active),callback:function ($$v) {_vm.$set(_vm.fields, "active", $$v)},expression:"fields.active"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","outlined":"","loading":_vm.loadingSave},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-content-save')}}),_vm._v(" Salvar ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }