import axios from '@/plugins/axios'

export default {

  async list (data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`associations?${params}`)
  },

  async get (id) {
    return await axios.get(`associations/${id}`)
  },

  async insert (data) {
    return await axios.post('associations', data)
  },

  async update (id, data) {
    return await axios.put(`associations/${id}`, data)
  },

  async delete (id) {
    return await axios.delete(`associations/${id}`)
  },

  async listContacts (associationId) {
    return await axios.get(`associations/${associationId}/contacts`)
  },

  async insertContact (associationId, data) {
    return await axios.post(`associations/${associationId}/contacts`, data)
  },

  async updateContact (associationId, contactId, data) {
    return await axios.put(`associations/${associationId}/contacts/${contactId}`, data)
  },

  async deleteContact (associationId, contactId) {
    return await axios.delete(`associations/${associationId}/contacts/${contactId}`)
  },

}
