<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="65%"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Contato
          <span
            class="body-2"
          >
            <i>{{ contact ? `Editando #${contact.id}` : 'Novo' }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="typeOfContactId"
              >
                <v-select
                  v-model="fields.typeOfContactId"
                  :items="typesOfContacts"
                  item-value="id"
                  item-text="description"
                  label="Tipo"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="name"
              >
                <v-text-field
                  v-model="fields.name"
                  hide-details="auto"
                  label="Nome"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="username"
              >
                <v-text-field
                  v-model="fields.username"
                  hide-details="auto"
                  label="Usuário"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="!this.contact" md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="password"
              >
                <v-text-field
                  v-model="fields.password"
                  hide-details="auto"
                  label="Senha"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="email"
              >
                <v-text-field
                  v-model="fields.email"
                  hide-details="auto"
                  label="E-mail"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="email2"
              >
                <v-text-field
                  v-model="fields.email2"
                  hide-details="auto"
                  label="E-mail 2"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="telephone"
              >
                <v-text-field
                  v-model="fields.telephone"
                  hide-details="auto"
                  label="Telefone"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="telephone2"
              >
                <v-text-field
                  v-model="fields.telephone2"
                  hide-details="auto"
                  label="Telefone 2"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="!isTownHall" md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="politicalParty"
              >
                <v-text-field
                  v-model="fields.politicalParty"
                  hide-details="auto"
                  label="Partido político"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="!isTownHall" md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="placeOfBirth"
              >
                <v-text-field
                  v-model="fields.placeOfBirth"
                  hide-details="auto"
                  label="Naturalidade"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="!isTownHall" md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="rg"
              >
                <v-text-field
                  v-model="fields.rg"
                  v-mask="'#################'"
                  hide-details="auto"
                  label="RG"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="!isTownHall" md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="cpf"
              >
                <v-text-field
                  v-model="fields.cpf"
                  v-mask="'###.###.###-##'"
                  hide-details="auto"
                  label="CPF"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="!isTownHall" md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="job"
              >
                <v-text-field
                  v-model="fields.job"
                  hide-details="auto"
                  label="Profissão"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="isTownHall" md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="site"
              >
                <v-text-field
                  v-model="fields.site"
                  hide-details="auto"
                  label="Site"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="!isTownHall" md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="genreId"
              >
                <v-select
                  v-model="fields.genreId"
                  :items="genres"
                  item-value="id"
                  item-text="description"
                  label="Gênero"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="isTownHall" md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="associationId"
              >
                <v-select
                  v-model="fields.associationId"
                  :items="associations"
                  item-value="id"
                  item-text="abbreviation"
                  label="Associação"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="!isTownHall" md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="educationDegreeId"
              >
                <v-select
                  v-model="fields.educationDegreeId"
                  :items="educationDegrees"
                  item-value="id"
                  item-text="description"
                  label="Escolaridade"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="!isTownHall" md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="civilStateId"
              >
                <v-select
                  v-model="fields.civilStateId"
                  :items="civilStates"
                  item-value="id"
                  item-text="description"
                  label="Estado Civil"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="isTownHall" md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="address"
              >
                <v-text-field
                  v-model="fields.address"
                  hide-details="auto"
                  label="Endereço"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="isTownHall" md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="cep"
              >
                <v-text-field
                  v-model="fields.cep"
                  v-mask="'#####-###'"
                  hide-details="auto"
                  label="CEP"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="!isTownHall" md="6">
              <v-menu
                v-model="birthDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="birthDate"
                  >
                    <v-text-field
                      v-model="formatedBirthDate"
                      v-mask="'##/##/####'"
                      label="Data de Nascimento"
                      hide-details="auto"
                      :error-messages="errors"
                      append-icon="mdi-calendar"
                      @blur="fields.birthDate = dateFormatIso(formatedBirthDate)"
                      @click:append="birthDateMenu = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="fields.birthDate"
                  no-title
                  class="ma-0"
                  @input="birthDateMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col v-if="isMayor || isDeputyMayor" md="6">
              <v-switch
                v-model="fields.reelected"
                class="mt-0 pt-4"
                label="Reeleito"
                hide-details
              />
            </v-col>
            <v-col md="6">
              <v-switch
                v-model="fields.active"
                class="mt-0 pt-4"
                label="Ativo"
                hide-details
              />
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon
            left
            v-text="'mdi-content-save'"
          />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import citiesApi from '@/api/cities'
  import associationsApi from '@/api/associations'
  import genresApi from '@/api/genres'
  import civilStatesApi from '@/api/civil-states'
  import educationDegreesApi from '@/api/education-degrees'
  import typesOfContactssApi from '@/api/types-of-contacts'
  import dateFormat from '@/utils/date-format'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      cityId: {
        type: Number,
      },
      contact: {
        type: Object,
        default: () => { },
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        formatedBirthDate: null,
        typesOfContacts: [],
        associations: [],
        genres: [],
        civilStates: [],
        educationDegrees: [],
        birthDateMenu: false,
        dateFormatIso: dateFormat.iso,
        fields: {
          typeOfContactId: null,
          active: true,
        }
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      isTownHall () {
        return this.fields.typeOfContactId === 11
      },
      isMayor () {
        return this.fields.typeOfContactId === 1
      },
      isDeputyMayor () {
        return this.fields.typeOfContactId === 15
      }
    },

    watch: {
      'fields.birthDate': {
        immediate: true,
        handler (newVal) {
          this.formatedBirthDate = dateFormat.ptBr(newVal)
        },
      },
    },

    mounted () {
      this.load()
    },

    methods: {

      async load () {
        try {
          this.loading = true
          
          if (this.contact) {
            this.fields = this.contact || {}
          }

          const [
            responseTypesOfContacts, responseGenres,
            responseCivilStates, responseAssociations,
            responseEducationDegrees
          ] = await Promise.all([
            typesOfContactssApi.list(), genresApi.list(),
            civilStatesApi.list(), associationsApi.list(),
            educationDegreesApi.list()
          ])

          this.typesOfContacts = responseTypesOfContacts.data.typesOfContacts
          this.genres = responseGenres.data.genres
          this.civilStates = responseCivilStates.data.civilStates
          this.associations = responseAssociations.data.associations
          this.educationDegrees = responseEducationDegrees.data.educationDegrees
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async save () {
        this.loadingSave = true
        this.$refs.observer.reset()

        try {
          const contact = { ...this.fields }

          contact.cityId = this.cityId

          if (this.contact) {
            await citiesApi.updateContact(this.cityId, this.contact.id, contact)
          } else {
            await citiesApi.insertContact(this.cityId, contact)
          }

          this.$snackbar.show({ color: 'success', message: 'Salvo com sucesso' })
          this.show = false
          this.$emit('save')
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          return
        } finally {
          this.loadingSave = false
        }
      },

    },

  }
</script>
