import axios from '@/plugins/axios'

export default {

  async list (data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`education-degrees?${params}`)
  },

}
